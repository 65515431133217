import { forwardRef } from "react";

export const ContainerOuter = forwardRef(function OuterContainer({ as: Tag, className, children, ...props }, ref) {
	Tag = Tag || "div";
	return (
		<Tag
			ref={ref}
			className={"sm:px-8 " + className}
			{...props}
		>
			<div className="mx-auto w-full max-w-7xl lg:px-8">{children}</div>
		</Tag>
	);
});

export const ContainerInner = forwardRef(function InnerContainer({ className, children, ...props }, ref) {
	return (
		<div
			ref={ref}
			className={"relative px-4 sm:px-8 lg:px-12 " + className}
			{...props}
		>
			<div className="mx-auto max-w-2xl lg:max-w-5xl">{children}</div>
		</div>
	);
});

export const Container = forwardRef(function Container({ children, ...props }, ref) {
	return (
		<ContainerOuter
			ref={ref}
			{...props}
		>
			<ContainerInner>{children}</ContainerInner>
		</ContainerOuter>
	);
});
